import Head from 'next/head'
import Link from 'next/link';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { Box, TextField, Typography, makeStyles, Button } from '@material-ui/core';

import Auth from '../layouts/auth';
import { AuthContext } from '../contexts/AuthContext';

import { cssProperties } from "../css/login"; // Get the css properties
import { io } from 'socket.io-client';
const useStyles = makeStyles((theme) => (cssProperties(theme))); // Set the css properties

const Login = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const { signIn } = useContext(AuthContext)

  const handleSignIn = async (data) => {
    await signIn(data)
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, shrink-to-fit=no" />
        <meta name="description" content="Fidli Admin - Web." />
        <meta name="keywords" content="Fidelização, VOID" />
        <meta name="author" content="VOID" />
        <title>Fidli Admin | Entrar  dfdfdfrs</title>
      </Head>


      <form onSubmit={handleSubmit(handleSignIn)} method="POST" id="loginForm">
        <Auth title="Fidli Admin | Entrar">
          <Box>
            <Typography variant="subtitle1" className={classes.subtitle}>
              iniciar sessão
            </Typography>
          </Box>
          <Box pt={3}>
            <TextField {...register('email')} type="email" size="small" id="email" placeholder="E-mail" variant="outlined" className={classes.inputs} inputRef={input => input && input.focus()} required />
          </Box>
          <Typography className={classes.notExistsError} id="notExistsError"></Typography>
          <Box py={1} pt={2}>
            <TextField {...register('password')} type="password" size="small" id="password" placeholder="******" variant="outlined" className={classes.inputs} required />
          </Box>
          <Box>
            <Typography className={classes.loginError} id="loginError"></Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" className={classes.line} >
              <Link href="/forgot">
                <a>esqueceu a palavra-passe?</a>
              </Link>
            </Typography>
          </Box>
          <Box py={2}>
            <Button disableElevation disableRipple type="submit" color="primary" variant='contained' className={classes.btn}>
              Entrar
            </Button>
          </Box>
        </Auth>
      </form>
    </>
  )
}

export default Login;